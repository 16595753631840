var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Settings"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[(_vm.setting)?_c('b-row',[_vm._l((_vm.setting),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"12"}},[(item.attribute == 'site_description')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.humanize(item.attribute),"label-for":_vm.humanize(item.attribute)}},[_c('validation-provider',{attrs:{"name":_vm.humanize(item.attribute),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'string')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.humanize(item.attribute),"label-for":_vm.humanize(item.attribute)}},[_c('validation-provider',{attrs:{"name":_vm.humanize(item.attribute),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'url')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.humanize(item.attribute),"label-for":_vm.humanize(item.attribute)}},[_c('validation-provider',{attrs:{"name":_vm.humanize(item.attribute),"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'boolean')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.humanize(item.attribute),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.humanize(item.attribute),"label-for":_vm.humanize(item.attribute),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"item.attribute + index","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.boolOptions},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),(item.data_type == 'float')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.humanize(item.attribute),"label-for":_vm.humanize(item.attribute)}},[_c('validation-provider',{attrs:{"name":_vm.humanize(item.attribute),"rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()])}),_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.success}},[_vm._v("Submit")])],2):_vm._e()],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,indexVal){return _c('li',{key:indexVal},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }