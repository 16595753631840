<template>
  <div>
    <b-card title="Settings">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row v-if="setting">
            <b-col cols="12" v-for="(item, index) in setting" :key="index">
              <div v-if="item.attribute == 'site_description'">
                <b-form-group
                  :label="humanize(item.attribute)"
                  :label-for="humanize(item.attribute)"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="humanize(item.attribute)"
                    rules="required"
                  >
                    <b-form-textarea
                      rows="3"
                      :id="item.attribute + index"
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="item.data_type == 'string'">
                <b-form-group
                  :label="humanize(item.attribute)"
                  :label-for="humanize(item.attribute)"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="humanize(item.attribute)"
                    rules="required"
                  >
                    <b-form-input
                      :id="item.attribute + index"
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="item.data_type == 'url'">
                <b-form-group
                  :label="humanize(item.attribute)"
                  :label-for="humanize(item.attribute)"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="humanize(item.attribute)"
                    rules="required|url"
                  >
                    <b-form-input
                      :id="item.attribute + index"
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="item.data_type == 'boolean'">
                <validation-provider
                  #default="{ errors }"
                  :name="humanize(item.attribute)"
                  rules="required"
                >
                  <b-form-group
                    :label="humanize(item.attribute)"
                    :label-for="humanize(item.attribute)"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="item.attribute + index"
                      v-model="item.value"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="boolOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="item.data_type == 'float'">
                <b-form-group
                  :label="humanize(item.attribute)"
                  :label-for="humanize(item.attribute)"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="humanize(item.attribute)"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                  >
                    <b-form-input
                      type="number"
                      min="0"
                      :id="item.attribute + index"
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-button variant="primary" block @click="success">Submit</b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required, url } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {

  mixins: [heightTransition],
  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      required,
      url,
      setting: null,
      boolOptions: ['true', 'false'],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('setting')
        .then((result) => {
          this.setting = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    success() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let index in setting) {
            if (this.setting[index].data_type == 'boolean') {
              if (this.setting[index].value == 'true') {
                this.setting[index].value = true
              } else {
                this.setting[index].value = false
              }
            }
          }
          const data = {
            setting: this.setting,
          }
          axios
            .post('setting', data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    humanize(str) {
      var i,
        frags = str.split('_')
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    },
    getBoolean(value) {
      switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
          return true
        default:
          return false
      }
    },
  },
}
</script>

